<template>
  <!-- 
    transition="dialog-bottom-transition"
    persistent
    style="min-height:60% !important"
    hide-overlay
 -->
  <div v-if="this.$root.mobile">
    <!-- <div
      class="text-center"
      style="width: 100%; position: absolute; top:27rem"
      v-if="loadingStatus"
    >
      <span class="text-muted font-size-h4">{{
        $t('QUES.noquestionsfound')
      }}</span>
    </div> -->
    <div v-if="question">
      <!-- question -->
      <div class="d-flex flex-column mb-5 align-items-start">
        <AvatarWithDetails
          v-if="question.owner"
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.question }}
        </div>
      </div>
      <!-- answer -->
      <!-- <div
        v-if="question.status == 'answered'"
        class="d-flex flex-column mb-5 align-items-end"
      >
        <AvatarWithDetails
          :user="question.doctor.doctor_id"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="true"
        ></AvatarWithDetails>
        <div
          class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.answer }}
        </div>
      </div> -->

      <!-- answer -->
      <div
        v-if="question.status == 'answered'"
        class="d-flex flex-column mb-5 align-items-end"
      >
        <AvatarWithDetails
          v-if="question.doctor"
          :user="question.doctor.doctor_id"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="true"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.answer }}
        </div>
      </div>

      <!-- rating -->
      <div
        v-if="question.rating"
        class="d-flex flex-column mb-5 align-items-start"
      >
        <AvatarWithDetails
          v-if="question.owner"
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left"
        >
          <v-rating :value="question.rating" readonly></v-rating>
        </div>
      </div>

      <!-- show form for mobile -->
      <div class="" v-if="showAnswerField">
        <!-- v-if="question && showAnswerFeild"c -->
        <b-form ref="ansForm" v-model="valid">
          <div
            class="card-footer align-items-center justify-content-center"
            style="display:flex; padding-right:5px; padding-left:5px"
          >
            <b-form-group
              :state="ansValidation"
              style="flex-grow:1; margin-bottom: 0px !important;"
              label-for="textarea-auto-height"
              invalid-feedback="Answer is required and has to be at least 5 characters"
            >
              <b-form-textarea
                :state="ansValidation"
                v-model="answer"
                id="textarea-auto-height"
                :placeholder="$t('QUES.answerf')"
                rows="2"
                class="border-0  p-0"
                required
              ></b-form-textarea>
            </b-form-group>
            <div>
              <v-btn
                icon
                fab
                color="primary"
                :disabled="!valid"
                v-on:click.prevent="submit"
                ><v-icon>mdi-send</v-icon></v-btn
              >
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <div v-else>
      <h5>👈 {{ $t("QUES.questionTip") }}</h5>
    </div>
  </div>

  <div v-else>
    <div v-if="question">
      <!-- question -->
      <div class="d-flex flex-column mb-5 align-items-start">
        <AvatarWithDetails
          v-if="question.owner"
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left min-w-300px"
        >
          {{ question.question }}
        </div>
      </div>
      <!-- answer -->
      <div
        v-if="question.status == 'answered'"
        class="d-flex flex-column mb-5 align-items-end"
      >
        <AvatarWithDetails
          v-if="question.doctor"
          :user="question.doctor.doctor_id"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="true"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-primary text-dark-50 font-weight-bold font-size-lg text-left min-w-400px"
        >
          {{ question.answer }}
        </div>
      </div>
      <!-- rating -->
      <div
        v-if="question.rating"
        class="d-flex flex-column mb-5 align-items-start"
      >
        <AvatarWithDetails
          v-if="question.owner"
          :user="question.owner"
          :detail="question.created_on"
          :isDate="true"
          :alignRight="false"
        ></AvatarWithDetails>
        <div v-else>avatar</div>
        <div
          class="mt-2 rounded p-5 bg-light-success text-dark-50 font-weight-bold font-size-lg text-left"
        >
          <v-rating :value="question.rating" readonly></v-rating>
        </div>
      </div>
    </div>
    <div v-else>
      <h5>👈 {{ $t("QUES.questionTip") }}</h5>
    </div>
  </div>
</template>

<script>
//import ApiService from "@/core/services/api.service";
import AvatarWithDetails from "@/view/pages/utilComponents/avatar1.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "QuestionsDetails",
  props: ["question", "showAnswerField"],
  components: { AvatarWithDetails },
  data() {
    return {
      valid: false,
      ansValidation: null,
      loadingStatus: true,
      answer: "",
      error: false,
      errorMsg: "",
    };
  },
  mounted() {},
  methods: {
    checkFormValidity() {
      const valid =
        this.$refs.ansForm.checkValidity() && this.answer.length > 4;

      this.ansValidation = valid;
      // this.valid = valid;
      return valid;
    },
    async submit() {
      try {
        if (this.checkFormValidity()) {
          var docid;
          if(this.$root.docID != 0)
          {
            docid = this.$root.docID;
          }
          else
          {
            try {
              const { data } = await ApiService.get(
                "items",
                "doctor?fields=id,bio,clinic,video,practice_started,department.name&filter[doctor_id]=" +
                  JSON.parse(localStorage.userInfo).id
              );
                this.$root.docID = data.data[0].id;
                docid = data.data[0].id;
              
            } catch (error) {
              console.log(error);
              this.error = error;
            }
          }
          const payload = { answer: this.answer, status: "answered", doctor:docid };
          this.valid = false;
          this.$emit("answer-sent");
          await ApiService.update(
            "items",
            `question/${this.question.id}`,
            payload
          );
          this.$emit("answer-success");
          this.answer = "";
          this.loadingStatus = false;
        } else {
          this.errorMsg = "please write answer";
          this.error = true;
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
