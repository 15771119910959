<template>
  <!-- start for mobile -->
  <div v-if="this.$root.mobile">
    <div
      class="text-center"
      style="margin-top:5vh;"
      v-if="loadingStatus"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>
    <v-list v-else one-line class="bg-transparent px-3">
      <template>
        <div v-for="item in questions" :key="item.id" class="mb-2 listitem">
          <v-list-item style="min-height:80px" @click="listItemClicked(item)">
            <v-list-item-avatar size="47" color="#F2F7FD">
              <v-img
                v-if="item.department.avatar != null" height="25" contain
                :src="$root.assetsBaseURL + ''+ item.department.avatar.data.asset_url"
              ></v-img>
              <div
                v-else
                style="text-transform: uppercase;width:100%"
                class="white--text font-size-h4"
              >
                {{ item.department.name.charAt(0) }}
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="listitemtitle" >{{ item.title }}
                  
                </div>
                <div class="listitemsubtitle">{{ questionsum(item.question) }}</div>
                <div class="listitemsubtitle">
                {{
                  (item.created_on)
                    | moment(
                      "timezone",
                      getTimeZone(),
                      "ddd DD/MM/YYYY hh:mm A"
                    )
                }}
                {{ (item.created_on)
                | moment("timezone", getTimeZone(), "from", "now") }}</div>
            </v-list-item-content>
            
          </v-list-item>
        </div>
      </template>
    </v-list>
    <div
      class="text-center"
      style="margin-top:5vh;"
      v-if="questions.length == 0 && !loadingStatus"
    >
    <lottie-animation
              :animationData="require('@/assets/animation/confetti.json')"
              :loop="true"
              style="height:120px"
            />
      <span class="text-muted font-size-h4">{{
        $t("QUES.noquestionsunanswered")
      }}</span>
      
    </div>
  </div>
  <!-- end for mobile -->

  <!-- start for web -->
  <v-card class="elevation-0" v-else>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('APPOINTMENTS.APP.search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :items="questions"
      :search="search"
      :loading="this.loadingStatus"
      :locale="this.$root.lang"
      :headers="headers"
      :loading-text="$t('tLoading')"
      @click:row="rowclicked"
      hide-default-header
    >
      <template v-slot:item.owner.first_name="{ item }">
        <AvatarWithDetails
          :user="item.owner"
          :detail="questionsum(item.question)"
          :isDate="false"
          :alignRight="false"
        ></AvatarWithDetails>
      </template>

      <template v-slot:item.title="{ item }"> </template>
      <template v-slot:item.created_on="{ item }">
        <div
          class="d-flex align-items-center "
          v-b-tooltip.hover.left="setToolTip(item.created_on)"
        >
          <span
            class="text-muted font-weight-bold font-size-sm text-right"
            style="width:100%"
            >{{ item.created_on | moment("from", "now") }}</span
          >
        </div>
      </template>
    </v-data-table>
    <!-- {{ reload }} -->
  </v-card>
  <!-- end for web -->
</template>

<script>
import ApiService from "@/core/services/api.service";
import AvatarWithDetails from "@/view/pages/utilComponents/avatar1.vue";
import LottieAnimation from "lottie-web-vue";

// import { mapActions, mapGetters } from 'vuex';

export default {
  name: "QuestionsUnaswered",
  props: ["depart"],
  components: { AvatarWithDetails,LottieAnimation },
  data() {
    return {
      search: "",
      error: "",
      loadingStatus: true,
      questions: [],
      headers: [
        {
          text: this.$t("QUES.header.user"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        { text: this.$t("QUES.header.title"), value: "title" },
        {
          text: this.$t("QUES.header.date"),
          value: "created_on",
          align: "right"
        }
      ],
      lastSelected: null
    };
  },
  mounted() {
    // this.fetchQuestions(this.depart);
    // this.getQuestions;
    this.getQuestions();
  },
  methods: {
    // ...mapActions(['fetchQuestions']),
    getTimeZone() {
      return localStorage.timezone;
    },
    async getQuestions() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;
      try {
        this.loadingStatus = true;
        const { data } = await ApiService.get(
          "items",
          "question?fields=id,created_on,status,owner.*.*,title,question,answer,rating,department.*.*,doctor.doctor_id.*.*&filter[department]=" +
            this.depart +
            "&filter[status]=notanswered&sort=-created_on"
        );
        this.questions = data.data;
        this.loadingStatus = false;
      } catch (error) {
        this.error = error;
        console.log(error);
      }

      // await ApiService.get(
      //   'items',
      //   'question?fields=id,status,created_on,title,question,owner.id,owner.first_name,owner.last_name,owner.avatar.data&filter[department]=' +
      //     this.depart +
      //     '&filter[status]=notanswered'
      // )
      //   .then(({ data }) => {
      //     ////console.log(data.data);
      //     this.questions = data.data;
      //     this.loadingStatus = false;
      //   })
      //   .catch((error) => {
      //     this.error = error;
      //   });
    },
    setToolTip(date) {
      return this.$moment(date).format("dddd, MMMM Do YYYY");
    },
    rowclicked(value, row) {
      if (this.lastSelected) {
        this.lastSelected.select(false);
      }
      row.select(true);
      this.lastSelected = row;
      this.$emit("questionClicked", value);
    },
    questionsum(q) {
      if (q.length < 50) {
        return q;
      } else {
        return q.substring(0, 50) + " ...";
      }
    },
    clearSelection() {
      if (this.lastSelected) {
        this.lastSelected.select(false);
        this.lastSelected = null;
      }
    },
    listItemClicked(value) {
      this.$emit("questionClicked", value);
    }
  },
  computed: {
    filteredQuestions() {
      return this.questions.filter(
        question =>
          // question.question.indexOf(this.search) !== -1 ||
          // question.answer.indexOf(this.search) !== -1
          question.question.match(this.search) ||
          question.answer.match(this.search)
      );
    }
    // ...mapGetters(['getQuestions', 'getLoadingStatus']),
  }
};
</script>
