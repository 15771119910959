<template>
        <div class="d-flex align-items-center" v-if="!alignRight">
          <span v-if="isAnon" class="symbol symbol-circle symbol-45 symbol-light-primary mr-3">
            <span class="symbol-label font-size-h5 font-weight-bold" style="text-transform: uppercase;">D</span>
          </span>
          <span v-else-if="user.avatar" class="symbol symbol-circle symbol-45 symbol-light-primary mr-3">
            <v-img 
              contain
              max-height="45px"
              max-width="45px"
              style="border-radius: 50%;"
              lazy-src="/media/users/100_1.jpg"
              :src="user.avatar.data.thumbnails[0].url"
            ></v-img>
          </span>
          <span v-else class="symbol symbol-circle symbol-45 symbol-light-primary mr-3">
            <span class="symbol-label font-size-h5 font-weight-bold" style="text-transform: uppercase;">{{user.first_name.charAt(0)}}</span>
          </span>
          <div class="d-flex flex-column text-left">
            <span v-if="isAnon" class="text-dark-75 font-weight-bolder font-size-lg">{{ 'Doctory User' }}</span>
            <span v-else class="text-dark-75 font-weight-bolder font-size-lg">{{ user.first_name }} {{ user.last_name }}</span>
            <span v-if="isDate" class="text-muted font-weight-bold font-size-sm">{{ detail | moment("from", "now") }}</span>
            <span v-else class="text-muted font-weight-bold font-size-sm">{{ detail }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center" v-else>
          <div class="d-flex flex-column text-right">
            <span v-if="isAnon" class="text-dark-75 font-weight-bolder font-size-lg">{{ 'Doctory User' }}</span>
            <span v-else class="text-dark-75 font-weight-bolder font-size-lg">{{ user.first_name }} {{ user.last_name }}</span>
            <span v-if="isDate" class="text-muted font-weight-bold font-size-sm">{{ detail | moment("from", "now") }}</span>
            <span v-else class="text-muted font-weight-bold font-size-sm">{{ detail }}</span>
          </div>
          <span v-if="isAnon" class="symbol symbol-circle symbol-45 symbol-light-primary ml-3">
            <span class="symbol-label font-size-h5 font-weight-bold" style="text-transform: uppercase;">{{user.first_name.charAt(0)}}</span>
          </span>
          <span v-else-if="user.avatar" class="symbol symbol-circle symbol-45 symbol-light-primary ml-3">
            <img alt="Pic" :src="user.avatar.data.thumbnails[0].url"/>
          </span>
          <span v-else class="symbol symbol-circle symbol-45 symbol-light-primary ml-3">
            <span class="symbol-label font-size-h5 font-weight-bold" style="text-transform: uppercase;">{{user.first_name.charAt(0)}}</span>
          </span>
        </div>
</template>

<script>
//import ApiService from "@/core/services/api.service";

  export default {
    name:"AvatarWithDetails",
    props: ['user', 'detail', 'isDate', 'alignRight','isAnon'],
  data () {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
  
    
  }
  }

</script>

