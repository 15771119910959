<template>
  <!-- <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->

  <!-- start for mobile -->
  <div v-if="this.$root.mobile">
    <!-- :style="checkMargin()" -->
    
    <div class="row" style="justify-content: center;">
        
        <div class=" mb-3" style="display:flex; justify-content:center">
      <v-btn-toggle
        v-model="selectedCategory"
        mandatory
        rounded
        
        class="mx-0 blue primary--text"
      >
        <v-btn active-class="activebtn" width="31vw" class="primary--text">
          {{ $t("QUES.tabs.unanswered") }}
        </v-btn>
        <v-btn active-class="activebtn" width="31vw" class="primary--text">
          {{ $t("QUES.tabs.answered") }}
        </v-btn>
         <v-btn active-class="activebtn" width="31vw" class="primary--text">
          {{ $t("QUES.tabs.byme") }}
        </v-btn>
      </v-btn-toggle>
    </div>

      <div v-if="department != 0" style="width:100%;">
        <!-- <QuestionsUnanswered
          :depart="questionCateogry"
          @listQuestionClicked="showQuestionBottom($event)"
        /> -->

        <QuestionsAnswered
          ref="quAns"
          v-if="selectedCategory === 1"
          :depart="department"
          @questionClicked="showQuestionBottom($event)"
        />

        <QuestionsUnanswered
          ref="quAll"
          v-if="selectedCategory === 0"
          :depart="department"
          @questionClicked="showQuestionBottom($event)"
          :reloadQuestions="getIsQuestionAnswered"
        />

        <QuestionsAnsweredByMe
          ref="quAnsMe"
          v-if="selectedCategory === 2"
          :depart="department"
          @questionClicked="showQuestionBottom($event)"
        />

        <v-bottom-sheet v-model="qsheet">
          <v-card
            class="rounded-lg"
            style="
              border-bottom-left-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            "
            :style="
          'padding-bottom: ' + this.$root.tabspadding + 'px;'"
          >
            <v-toolbar flat dark color="white" class="text-primary">
              <v-toolbar-title v-if="selectedQuestion != null">{{selectedQuestion.title}}</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark color="primary" @click="qsheet = !qsheet">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text class="p-3">
              <QuestionsDetails
                :showAnswerField="showAnswerField"
                :question="selectedQuestion"
                @answer-sent="
                  qsheet = false;
                  $refs.quAll.loadingStatus = true;
                "
                @answer-success="answerSuccess"
              ></QuestionsDetails>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
      </div>
      <!-- <div v-if="!hideQuestionDetails" :class="questionDetailCol">
        <QuestionsDetails :question="selectedQuestion"></QuestionsDetails>
      </div> -->
    </div>
  </div>
  <!-- end for mobile -->

  <!-- start for web -->
  <div v-else>
    <!-- v-if="!dataLoaded" -->
    <!-- <div
      class="text-center mt-5"
      style="width:100%; margin-right:auto; margin-left:auto;"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div> -->
    <div class="row" style="position:relative">
      <!-- <div
        v-if="dataLoading"
        class="text-center mt-5"
        style="position:absolute;z-index:1000;width:100%;height:100; top:50%;"
      >
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div> -->
      <div class="col-md-5">
        <div class="card card-custom gutter-b">
          <div
            v-if="!gotDepartment"
            class="text-center mt-5 mb-5"
            style="width:100%; margin-right:auto; margin-left:auto;"
          >
            <b-spinner
              variant="primary"
              type="grow"
              label="Spinning"
            ></b-spinner>
          </div>
          <div v-else>
            <b-tabs card @activate-tab="tabChanged">
              <b-tab :title="$t('QUES.tabs.unanswered')" active class="p-0">
                <b-card-text
                  ><QuestionsUnanswered
                    ref="quAll"
                    :depart="department"
                    @questionClicked="setQuestion($event)"
                    :reloadQuestions="getIsQuestionAnswered"
                  ></QuestionsUnanswered
                ></b-card-text>
              </b-tab>
              <b-tab :title="$t('QUES.tabs.answered')" class="p-0">
                <b-card-text
                  ><QuestionsAnswered
                    ref="quAns"
                    :depart="department"
                    @questionClicked="setQuestion($event)"
                  ></QuestionsAnswered
                ></b-card-text>
              </b-tab>
              <b-tab :title="$t('QUES.tabs.byme')" class="p-0">
                <b-card-text
                  ><QuestionsAnsweredByMe
                    ref="quAnsMe"
                    :depart="department"
                    @questionClicked="setQuestion($event)"
                  ></QuestionsAnsweredByMe
                ></b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <span v-if="!selectedQuestion"> {{ $t("QUES.question") }}</span>
              <span v-else> {{ selectedQuestion.title }}</span>
            </div>
          </div>
          <div class="card-body">
            <QuestionsDetails :question="selectedQuestion"></QuestionsDetails>
          </div>
          <b-form ref="ansForm" v-model="valid">
            <div
              v-if="selectedQuestion && showAnswerFeild"
              class="card-footer align-items-center"
            >
              <!--begin::Compose-->
              <!-- <b-form-group
                :state="ansValidation"
                label="Answer"
                label-for="textarea-auto-height"
                invalid-feedback="Answer is required"
              > -->
              <!-- @blur="checkValidation" -->
              <b-form-group
                :state="ansValidation"
                label-for="textarea-auto-height"
                invalid-feedback="Answer is required and has to be at least 5 characters"
              >
                <b-form-textarea
                  :state="ansValidation"
                  v-model="answer"
                  id="textarea-auto-height"
                  :placeholder="$t('QUES.answerf')"
                  rows="3"
                  class="border-0  p-0"
                  required
                ></b-form-textarea>
              </b-form-group>
              <div
                class="d-flex align-items-center justify-content-between mt-5"
              >
                <!-- <div class="mr-3"></div> -->
                <!-- <div> -->
                <v-spacer></v-spacer>
                <button
                  :disabled="!valid"
                  @click="submit"
                  type="button"
                  class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6"
                >
                  {{ $t("QUES.send") }}
                </button>
                <!-- </div> -->
              </div>
              <!--begin::Compose-->
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionsUnanswered from "./comp/questions/quAll.vue";
import QuestionsAnswered from "./comp/questions/quAnswered.vue";
import QuestionsAnsweredByMe from "./comp/questions/quAnsweredMe.vue";
import QuestionsDetails from "./comp/questions/quDetails.vue";
import ApiService from "@/core/services/api.service";

// import { mapActions, mapGetters } from 'vuex';
export default {
  name: "questions",
  components: {
    QuestionsUnanswered,
    QuestionsDetails,
    QuestionsAnswered,
    QuestionsAnsweredByMe
  },
  data() {
    return {
      dataLoading: false,
      department: "0",
      gotDepartment: false,
      selectedQuestion: null,
      showAnswerFeild: true,
      error: false,
      errorMsg: "",
      answer: "",
      valid: false,
      // statedChaned: false,
      isQuestionAnswered: false,
      ansValidation: null,
      sheet: false,
      qsheet: false,
      showmine: true,
      showall: false,
      showAnswerField: true,
      // hideQuestionDetails: true,
      selectedCategory: 0,
      active_tab:["active_tab", ""],
      // showAnswered: true,
      // showUnAnswered: false,
      // showAnsweredByMe: false,
      // selectedMine: 0,

      questionCateogry: "answered",
      questionDetailCol: "col-4  rounded"
    };
  },
  computed: {
    // ...mapGetters(['getQuestions']),
    getIsQuestionAnswered() {
      return this.isQuestionAnswered;
    }
  },
  mounted() {
    this.$root.title = this.$t("MENU.questions");
    console.log(this.$refs);
  },
  created() {
    this.getDepartment();
  },
  destroyed() {},
  methods: {
    getDepartment() {
      console.log("getting department and doc id is", this.$root.docID)
      if (localStorage.DEPARTMENT) {
        this.department = localStorage.DEPARTMENT;
        this.gotDepartment = true;
      } else {
        ApiService.get("items", "doctor/" + this.$root.docID + "?fields=department")
          .then(({ data }) => {
            console.log("getting department, data", data)
            this.department = data.data.department;
            window.localStorage.setItem("DEPARTMENT", data.data.department);
            this.gotDepartment = true;
          })
          .catch(error => {
            this.error = error;
            console.log("getting department, error", error)
          });
      }
    },
    setQuestion(q) {
      this.selectedQuestion = q;
      // this.hideQuestionDetails = false;
    },
    switchTab(clicked) {
      if (clicked == 0) {
        this.selectedCategory = 1
        this.showAnswerField = true
        this.questionCateogry = 'unAnswered';
        this.active_tab[0]= "active_tab";
        this.active_tab[1]= "";
        this.active_tab[2]= "";
      } else if (clicked == 1) {
        this.selectedCategory = 2
        this.showAnswerField = false
        this.questionCateogry = 'answered';
        this.active_tab[0]= "";
        this.active_tab[1]= "active_tab";
        this.active_tab[2]= "";
      }
      else if (clicked == 2) {
        this.selectedCategory = 3
        this.showAnswerField = false
        this.questionCateogry = 'answeredByMe';
        this.active_tab[0]= "";
        this.active_tab[1]= "";
        this.active_tab[2]= "active_tab";
      }
    },
    tabChanged(event) {
      if (event == 0) {
        this.showAnswerFeild = true;
        this.$refs.quAll.clearSelection();
        this.questionAnswered = true;
      } else if (event == 1) {
        this.showAnswerFeild = false;
        this.$refs.quAns.clearSelection();
      } else if (event == 2) {
        this.showAnswerFeild = false;
        this.$refs.quAnsMe.clearSelection();
      }
      this.selectedQuestion = null;
    },
    checkFormValidity() {
      const valid =
        this.$refs.ansForm.checkValidity() && this.answer.length > 4;

      this.ansValidation = valid;
      // this.valid = valid;
      return valid;
    },
    async submit() {
      try {
        if (this.checkFormValidity()) {
          var docid;
          if(this.$root.docID != 0)
          {
            docid = this.$root.docID;
          }
          else
          {
            try {
              const { data } = await ApiService.get(
                "items",
                "doctor?fields=id,bio,clinic,video,practice_started,department.name&filter[doctor_id]=" +
                  JSON.parse(localStorage.userInfo).id
              );
                this.$root.docID = data.data[0].id;
                docid = data.data[0].id;
              
            } catch (error) {
              console.log(error);
              this.error = error;
            }
          }
          const payload = { answer: this.answer, status: "answered" ,doctor:docid};
          this.valid = false;
          this.$refs.quAll.loadingStatus = true;
          await ApiService.update(
            "items",
            `question/${this.selectedQuestion.id}`,
            payload
          );
          this.$refs.quAll.getQuestions();
          this.$refs.quAns.getQuestions();
          this.$refs.quAnsMe.getQuestions();
          this.$refs.quAll.loadingStatus = false;
          this.answer = "";
          this.toast(
            "b-toaster-top-center",
            "success",
            "answered added successfully"
          );
        } else {
          this.errorMsg = "please write answer";
          this.error = true;
          console.log("bad");
          return;
        }
      } catch (error) {
        console.log(error);
      }
    },
    answerSuccess() {
      // this.qsheet = false;
      this.$refs.quAll.loadingStatus = false;
      this.toast(
        "b-toaster-top-center",
        "success",
        "answered added successfully"
      );
      this.$refs.quAll && this.$refs.quAll.getQuestions();
      this.$refs.quAns && this.$refs.quAns.getQuestions();
      this.$refs.quAnsMe && this.$refs.quAnsMe.getQuestions();
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    },
    showQuestionBottom(e) {
      this.selectedQuestion = e;
      this.qsheet = true;
    },
    displayQCat() {
      if (this.questionCateogry == "answered") {
        return this.$t("QUES.tabs.answered");
      } else if (this.questionCateogry == "unAnswered") {
        return this.$t("QUES.tabs.unanswered");
      } else if (this.questionCateogry === "answeredByMe") {
        return this.$t("QUES.tabs.byme");
      }
    }
  }
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
.v-application--wrap {
  min-height: 0px !important;
}
.activebtn{
  border-style: solid;
  border-color: #d2e1f2 !important;
  background: white !important;
  border-width: 4px !important;
  color: #0971ce !important;
}

</style>
<style scoped>
.theme--light.v-btn-toggle:not(.v-btn-toggle--group)  >>>  .v-btn.v-btn
{
  border-color: #d2e1f2 !important;
  border-width: 0px
}

.v-btn:before
{
  background-color: transparent !important;
}
</style>